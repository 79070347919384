@media screen and (min-width: 1200px) and (min-width: 1000px) {
  .me-auto {
    margin-left: 50px !important;
    gap: 30px !important;
  }
}

#preloader.isdone {
  visibility: hidden;
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

#preloader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;
  pointer-events: none;
}

#preloader.isdone:after,
#preloader.isdone:before {
  height: 0;
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition-delay: 1s;
}

#preloader:before {
  top: 0;
}

#preloader:after,
#preloader:before {
  content: "";
  position: fixed;
  left: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(90deg, #0c3df4 0, #02b5ff);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

#preloader:after {
  bottom: 0;
}

.top-navbar.style-4 {
  background-image: url(assets/top_nav_s4.png);
  background-size: cover;
  background-position: top;
  padding: 10px 15px 50px;
  text-align: center;
}
.top-navbar {
  z-index: 9999;
}

.butn.sm-butn,
.btn.sm-butn {
  padding: 10px 25px;
}

.fs-10px {
  font-size: 10px !important;
  color: white;
}

.brd-gray {
  border-color: #0002 !important;
}

.butn.sm-butn span,
.btn.sm-butn span {
  font-size: 12px;
}

.butn span,
.btn span {
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: capitalize;
}

.icon-15 {
  width: 15px;
  height: 15px;
}

.op-6 {
  opacity: 0.6;
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  /* Adjust based on your line-height */
}

.op-10 {
  opacity: 1;
}

@media screen and (min-width: 991px) {
  .bg-light1 {
    width: 100%;
    background-color: #f0eff5 !important;
  }
}

@media screen and (max-width: 991px) {
  .navbar.style-4 {
    background-color: #f0eff5 !important;
    padding: 0 0 !important;
  }
}

@media screen and (max-width: 991px) {
  header.style-4 .content .info h1 {
    font-size: 25px !important;
    line-height: 40px !important;
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 991px) {
  header.style-4 .content .info {
    margin-bottom: 50px;
  }
}

header.style-4 .content .info h1 span {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#501e9c),
    color-stop(30%, #8169f1),
    color-stop(30%, #8169f1),
    color-stop(73%, #a44cee),
    to(#ff847f)
  );
  background-image: -webkit-linear-gradient(
    left,
    #501e9c 0%,
    #8169f1 30%,
    #8169f1 30%,
    #a44cee 73%,
    #ff847f 100%
  );
  background-image: -o-linear-gradient(
    left,
    #501e9c 0%,
    #8169f1 30%,
    #8169f1 30%,
    #a44cee 73%,
    #ff847f 100%
  );
  background-image: linear-gradient(
    to right,
    #501e9c 0%,
    #8169f1 30%,
    #8169f1 30%,
    #a44cee 73%,
    #ff847f 100%
  );
}

.navbar.style-4 {
  position: relative;
  z-index: 99;
  padding: 10px 0px;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -38px;
  width: 100%;
}

.navbar.style-4 .navbar-brand {
  width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
}

.navbar.style-4 .navbar-nav .nav-item .nav-link:hover,
.navbar.style-4 .navbar-nav .nav-item .nav-link.active {
  color: #5842bc;
}

.navbar .nav-link {
  cursor: pointer;
}

.navbar.style-4 .navbar-nav .nav-item .nav-link {
  position: relative;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  margin: 0 15px;
}

.nav-link {
  position: relative !important;
  color: #000 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin: 0 15px !important;
}

.navbar.style-4 .navbar-nav .nav-item .nav-link:hover::before,
.navbar.style-4 .navbar-nav .nav-item .nav-link.active::before {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(0);
  -ms-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
}

.navbar.style-4 .nav-side .search-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #0002;
  color: #000;
  font-size: 16px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (min-width: 991px) {
  .navbar.style-4 .navbar-nav .nav-item .nav-link::before {
    position: absolute;
    content: "";
    top: -42px;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-20px);
    -ms-transform: translateX(-50%) translateY(-20px);
    transform: translateX(-50%) translateY(-20px);
    width: 55px;
    height: 30px;
    background-image: url(assets/active_s4.png);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.navbar.style-4 .navbar-nav .nav-item .nav-link .hot {
  position: absolute;
  font-size: 8px;
  padding: 2px 3px;
  border-radius: 3px;
  top: -10px;
  right: 0;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .service2 {
    display: flex;
    border: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    left: -100px;
    border: none;
  }
}

@media (max-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border: none;
  }
}

@media screen and (max-width: 991px) {
  header.style-4 {
    overflow: hidden;
  }
}

header.style-4 {
  position: relative;
  padding: 60px 0;
}

header.style-4 .content {
  position: relative;
  overflow: hidden;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1170px;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.container,
.container-fluid {
  position: relative;
  z-index: 5;
}

* {
  outline: 0;
}

@media screen and (max-width: 991px) {
  header.style-4 .content .info .title_small {
    margin-bottom: 20px !important;
  }
}

header.style-4 .content .info .title_small {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f7f4ff;
  border-radius: 5px;
  color: #5842bc;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.small,
small {
  font-size: 13px;
  line-height: 1.7;
  display: inline-block;
}

header.style-4 .content .info h1 {
  font-size: 52px;
  line-height: 60px;
  color: #000;
}

.mb-30 {
  margin-bottom: 30px !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 700 !important;
  margin: 0 !important;
  line-height: 1.4;
}

@media screen and (max-width: 991px) {
  header.style-4 .content .info .mt-50 {
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 991px) {
  header.style-4 .content .info .small,
  header.style-4 .content .info small,
  header.style-4 .content .info .btn {
    font-size: 10px;
  }
}

@media screen and (max-width: 991px) {
  header.style-4 .content .info .mt-100 {
    margin-top: 50px !important;
  }
}

header.style-4 .content .info .play-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header.style-4 .content .info .text {
  color: #666;
  font-size: 15px;
}

.text {
  font-size: 14px;
  line-height: 2;
}

p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

a,
span,
img {
  display: inline-block;

  transition: all 0.3s ease;
  color: inherit;
  text-decoration: none !important;
}

h6:hover {
  color: #5842bc;
}

img {
  max-width: 100%;
  max-height: 100%;
}

header.style-4 .wave {
  position: absolute;
  left: -3%;
  bottom: -10px;
  width: 106%;
  max-width: unset;
  height: 240px;

  object-fit: cover;

  object-position: top;
  z-index: 20;
}

.wave {
  animation: wave 2s ease-in-out infinite alternate both;
  -webkit-animation: wave 2s ease-in-out infinite alternate both;
}

.img2 {
  max-width: 30% !important;
  max-height: 30% !important;
}

body {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  overflow-x: hidden;
  position: relative;
  font-weight: 400;
  line-height: 1.5;
}

header.style-4 .content .bubble {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: rotate-center 100s linear infinite both;
}

.buttonapp {
  padding: 12px 30px;
  position: relative;
  overflow: hidden;
  text-align: center;
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.bg-blue4 {
  background-color: #5842bc !important;
}

.colorv {
  color: #5842bc !important;
}

.clients.style-4 {
  background-color: #f0eff5;
  position: relative;
  z-index: 25;
}

@media screen and (max-width: 991px) {
  .about.style-4 .content {
    padding: 40px 0 !important;
  }
}

.mb-60 {
  margin-bottom: 60px !important;
}

h5,
h6 {
  font-weight: 500;
  margin: 0;
  line-height: 1.4 !important;
  margin-bottom: 0 !important;
}

.color-blue4 {
  color: #5842bc !important;
}

span {
  display: inline-block;
  transition: all 0.3s ease;
  text-decoration: none !important;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.clients.style-4 .client-logos {
  border-bottom: 1px solid #9994;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) {
  margin-right: -15px;
  margin-left: -15px;
}

@media screen and (min-width: 990px) {
  .team4 {
    display: flex;
    gap: 20px;
  }
}

@media screen and (max-width: 990px) {
  .card {
    margin-bottom: px;
    border-radius: px !important;
  }
}

.card {
  border-radius: 0px !important;
}

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}

.card-img-top {
  padding: px;
  border-radius: px !important;
  height: 200px;
  object-fit: cover;
}

@media (max-width: 700px) {
  .card-img-top {
    /* border-radius: px !important; */
    height: auto;
  }
}

.card-body {
  text-align: center;
}

.d-block {
  display: block !important;
  margin: 0px;
}

.clients.style-4 .client-logos .img img {
  filter: grayscale(1);
  opacity: 0.6;
}

.features.style-4 {
  background-color: #f0eff5;
  position: relative;
  overflow: hidden;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.section-head.style-4 .title_small {
  font-size: 12px;
  padding: 5px 10px;

  border-radius: 5px;
  color: #5842bc;
  margin-bottom: 20px;
}

.bg-white {
  background-color: #fff !important;
}

.section-head.style-4 h2 {
  font-size: 40px;
  line-height: 1.3;
  color: #000;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.section-head.style-4 h2 span {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #501e9c 0%,
    #8169f1 30%,
    #8169f1 30%,
    #a44cee 73%,
    #ff847f 100%
  );
}

.features.style-4 .content {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}

.features.style-4 .content .features-card {
  text-align: center;
}

.features.style-4 .content .features-card .icon {
  width: 125px;
  height: 125px;
  display: block;
  margin: 0 auto 30px;
  position: relative;
}

.img-contain img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.features.style-4 .content .features-card h6 {
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  margin: 20px;
}

.features.style-4 .img-circle {
  position: absolute;
  top: 40px;
  width: 76%;
  left: 12%;
  max-height: unset;
  animation: rotate-center 100s linear infinite both;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wave {
  0% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(-1deg);
  }
}

@media screen and (max-width: 991px) {
  .features.style-4 .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .section-head.style-4 h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 991px) {
  .section-head h2 {
    letter-spacing: 3px;
  }
}

.about.style-4 {
  overflow: hidden;
  position: relative;
}

.section-padding {
  padding: 30 px 0;
}

.about.style-4 .content {
  position: relative;
  padding: 60px 0;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) > * {
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

.section-head.style-4 .title_small {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f7f4ff;
  border-radius: 5px;
  color: #5842bc;
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 40px !important;
}

body ul {
  list-style-type: none;
  margin: 0;
}
ul {
  padding: 0 !important;
}

.icon-30 {
  width: 30px;
  height: 30px;
}

.bg-gray {
  background: gray !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.fa-tag:before {
  content: "\f02b";
}

.about.style-4 .content ul li h6 {
  font-size: 15px;
}

.butn small,
.btn small {
  font-size: 12px;
  position: relative;
  z-index: 2;
  text-transform: capitalize;
}

.about.style-4 .content.frs-content .lines,
.about.style-4 .content.trd-content .lines {
  position: absolute;
  left: 0;
  top: -80px;
  width: 40%;
  max-width: unset;
  max-height: unset;
}

.about.style-4 .content.frs-content .bubble,
.about.style-4 .content.trd-content .bubble {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  max-width: unset;
  max-height: unset;
  animation: rotate-center 100s linear infinite both;
  -webkit-animation: rotate-center 100s linear infinite both;
}

.faq.style-3.style-4 .accordion-item {
  border: 0;
  border-radius: 7px;
  overflow: hidden;
}

.faq.style-3 .accordion-item {
  background: transparent;
}
.faq.style-3.style-4 .accordion-item .accordion-button:not(.collapsed) {
  background-color: #f4f2fb;
  color: #5842bc;
  padding-bottom: 10px;
}

.faq.style-3.style-4 .accordion-item .accordion-button {
  padding: 15px;
  font-size: 15px;
}

.faq.style-3 .accordion-item .accordion-button {
  font-weight: bold;
  box-shadow: none;
  text-transform: capitalize;
  background-color: transparent;
}

.faq.style-3 .accordion-item .accordion-button::after {
  background-size: 15px;
}

.faq.style-3.style-4 .accordion-item .accordion-collapse {
  background-color: #f4f2fb;
}
.faq.style-3.style-4 .accordion-item .accordion-collapse .accordion-body {
  font-size: 13px;
}

.faq.style-3 .accordion-item .accordion-collapse .accordion-body {
  padding-top: 0;
  padding-bottom: 30px;
  color: #666;
}

.about.style-4 .btn-img {
  text-align: initial !important;
  display: inline-flex !important;
  background-color: #f4f2fb;
  -webkit-box-align: center;
  align-items: center;
}

.about.style-4 .btn-img .icon {
  flex-shrink: 0;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #0001;
  width: 45px;
}

.img-contain {
  display: block;
}

.about.style-4 .btn-img .inf {
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.about.style-4 .btn-img .inf small {
  font-size: 5px;
  color: #666;
}

.about.style-4 .btn-img .inf h6 {
  font-size: 12px;
  color: #000;
  font-weight: bold;
  line-height: 1.5;
}

.about.style-4 .content.sec-content .bubble2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  max-width: unset;
  max-height: unset;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.op-4 {
  opacity: 0.4;
}

.bi {
  display: inline-block;
}

.butn,
.btn {
  padding: 12px 30px !important;
  position: relative;
  overflow: hidden;
  display: inline-flex !important;
  text-align: center !important;
  -webkit-box-pack: center;
  justify-content: center;
}

.about.style-4 .integration {
  position: relative;
}

.pt-60 {
  padding-top: 60px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.about.style-4 .integration .content {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
}

.about.style-4 .integration .content .img:nth-of-type(1) {
  animation-delay: -0.5s;
}

.about.style-4 .integration .content .img {
  animation: slide_up_down 1.7s ease-in-out infinite alternate both;
}

.mt-30 {
  margin-top: 30px !important;
}

.about.style-4 .integration .content .img:nth-of-type(2) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.about.style-4 .integration .content .img:nth-of-type(3) {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.about.style-4 .integration .content .img:nth-of-type(4) {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.about.style-4 .integration .content .img:nth-of-type(5) {
  -webkit-animation-delay: -2.5s;
  animation-delay: -2.5s;
}

.about.style-4 .integration .intg-back {
  position: absolute;
  left: 0;
  bottom: 19%;
  width: 100%;
  max-height: unset;
  object-fit: cover;
}

.about.style-4 .top-wave {
  position: absolute;
  top: -2px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .testimonials.style-4 {
    overflow: hidden;
    background-color: #f0eff5;
  }
}

.testimonials.style-4 {
  overflow: hidden;
  background-color: #f0eff5;
}

.about.style-4 .bottom-wave {
  position: absolute;
  bottom: -2px;
  width: 100%;
  transform: rotate(180deg);
}

@keyframes slide_up_down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

.mad1 {
  transition-duration: 0ms;
  transform: translate3d(-837.6px, 0px, 0px);
}

.testimonials.style-4 .content {
  border-bottom: 1px solid #9993;
  padding-bottom: 80px;
}

.testimonials.style-4 .content .numbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonials.style-4 .content .numbs .num-card:not(:last-of-type) {
  padding-right: 50px;
  margin-right: 50px;
  border-right: 1px solid #9993;
}

.testimonials.style-4 .content .numbs .num-card .icon {
  height: 55px;
  display: inline-block;
  margin-bottom: 15px;
}

.testimonials.style-4 .content .numbs .num-card h2 {
  color: #5842bc;
}
.testimonials.style-4 .content .numbs .num-card p {
  font-size: 11px;
  color: #666;
  margin-top: 5px;
}

.testimonials.style-4 .content .stars {
  font-size: 10px;
  color: #fdb900;
  margin-bottom: 5px;
}

.testimonials.style-4 .content .play-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonials.style-4 .content .play-btn .icon {
  width: 42px;
  height: 42px;
  border: 1px solid #5842bc99;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.testimonials.style-4 .content .testi-cards {
  position: relative;
  padding: 0 5vw;
}
.client_card {
  position: relative;
  z-index: 10;
  border-radius: 15px;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-box-shadow: -4.104px 11.276px 38px 0px rgb(45 42 61 / 10%);
  box-shadow: -4.104px 11.276px 38px 0px rgb(45 42 61 / 10%);
  margin: 20px;
}

.testimonials.style-4 .content .testi-cards .client_card .user_img {
  margin-right: 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.testimonials.style-4 .content .testi-cards .client_card .user_img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonials.style-4 .content .testi-cards .client_card:nth-of-type(2) {
  right: -5vw;
}

.testimonials.style-4 .content .testi-cards .client_card {
  position: relative;
  z-index: 10;
  border-radius: 15px;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-box-shadow: -4.104px 11.276px 38px 0px rgb(45 42 61 / 10%);
  box-shadow: -4.104px 11.276px 38px 0px rgb(45 42 61 / 10%);
  margin: 20px;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content h6 {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 15px;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content p {
  font-size: 10px;
  color: #000;
  text-transform: uppercase;
}

.testimonials.style-4 .content .testi-cards .testi-globe {
  position: absolute;
  top: -80px;
  left: 0;
  width: 110%;
  max-height: unset;
  max-width: unset;
  opacity: 0.2;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content p span {
  text-transform: capitalize;
  color: #2e99f5;
}

@media screen and (max-width: 991px) {
  .testimonials.style-4 {
    overflow: hidden;
  }
}

@media screen and (max-width: 991px) {
  .testimonials.style-4 .content .testi-cards .client_card {
    right: 0 !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 20px 0 !important;
  }
}

.mt-70 {
  margin-top: 70px !important;
}

@media screen and (max-width: 900px) {
  .testimonials.style-4 .content .numbs {
    display: flex;
    justify-content: center;
  }
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pricing.style-4 .form-check-input.bg-blue4 {
  background-color: var(--color-blue4);
  border-color: #5842bc;
}

.form-switch .form-check-input {
  margin: 0 15px !important;
  width: 2.5em !important;
  height: 1.3em;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.pricing.style-4 .content .row .col-lg-6:nth-of-type(1) .price-card {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  margin-right: -1px;
}

.pricing.style-4 .content .price-card {
  position: relative;
  border: 1px solid #e9e8ee;
  border-radius: 15px;
  padding: 40px;
}

.pricing.style-4 .content .price-card .price-header {
  border-bottom: 1px solid #e9e8ee;
}
.pricing.style-4 .content .price-card .price-header h6 {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pricing.style-4 .content .price-card .price-header h6 .icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.pricing.style-4 .content .price-card .price-header h2 {
  font-size: 37px;
  color: #000;
  margin: 12px 0 5px;
}

.pricing.style-4 .content .price-card .price-header h2 small {
  font-size: 15px;
  font-weight: 300;
}

.pricing.style-4 .content .price-card .price-header p {
  font-size: 12px;
  color: #666;
}

.op-3 {
  opacity: 0.3;
}

.pricing.style-4 .content .row .col-lg-6:nth-of-type(2) .price-card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #f8f7fd;
}

.pricing.style-4 .content .price-card .off {
  position: absolute;
  width: 85px;
  height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  background-image: url(assets/off.png);
  background-size: contain;
  background-repeat: no-repeat;
  top: -20px;
  right: 45px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.community.style-4 .content {
  border: 1px solid #9993;
  border-radius: 15px;
  padding: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.community.style-4 .content .commun-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 33%;
  border-right: 1px solid #9993;
  padding: 20px 0;
}

.community.style-4 .content .commun-card .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 25px;
  margin-top: -25px;
}

.community.style-4 .content .commun-card .inf h5 {
  font-size: 23px;
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.1;
}
.community.style-4 .content .commun-card .inf p {
  font-size: 12px;
  color: #666;
}

@media screen and (max-width: 991px) {
  .section-padding {
    padding: 70px 0;
  }
}

@media screen and (max-width: 991px) {
  .community.style-4 .content,
  .community.style-5 .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 20px !important;
  }
}

@media screen and (max-width: 991px) {
  .community.style-4 .content .commun-card,
  .community.style-5 .content .commun-card {
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding: 20px 6px 5px 36px !important;
  }
}

footer.style-4 {
  position: relative;
  background-color: #f0eff5;
}
footer.style-4 .container {
  position: relative;
  z-index: 25;
}

.border-blue4 {
  border-color: #5842bc !important;
}

.apple4 {
  margin-top: -6px;
}

footer.style-4 .foot {
  border-bottom: 1px solid #9999;
  padding: 30px 0;
}

.mt-80 {
  margin-top: 80px !important;
}
footer.style-4 .foot .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.me-21 {
  bottom: 18px;
  left: 10px;
}

@media screen and (min-width: 991px) {
  footer.style-4 .foot .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
  }
}

footer.style-4 .foot .links li a:hover,
footer.style-4 .foot .links li a.active {
  color: #5842bc;
}

footer.style-4 .foot .links li a {
  font-size: 12px;
  margin: 0 15px;
  font-weight: bold;
}

.img-cover {
  display: block;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.icon-25 {
  width: 25px;
  height: 25px;
}

.img-cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.dropdown-toggle::after {
  position: relative;
  opacity: 0.8;
  content: "\f078";
  font-weight: 900;
  font-family: "Font Awesome 5 pro";
  border: 0;
  font-size: 80%;
}

.dropdown-menu {
  min-width: 170px;
  padding: 15px 0;
  border-radius: 15px;
  border: 0;
  -webkit-box-shadow: 0 0 20px #0001;
  box-shadow: 0 0 20px #0001;
  top: 100%;
}
.dropdown-menu .dropdown-item {
  padding: 8px 20px;
  font-size: 12px;
}
footer.style-4 .copywrite {
  padding: 35px 0;
}

footer.style-4 .wave {
  position: absolute;
  left: -3%;
  bottom: 95%;
  width: 103%;
  max-width: unset;
  height: 240px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  z-index: 20;
}

.btn:hover {
  border: none !important;
}

@media screen and (max-width: 991px) {
  footer.style-4 .d-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  footer.style-4 .d-flex .btn.me-4 {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  footer.style-4 .d-flex .btn {
    width: 100%;
    margin: 10px 0;
  }
}

@media screen and (max-width: 991px) {
  footer .foot {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 991px) {
  footer.style-4 .foot .logo {
    width: 160px;
  }
}

@media screen and (max-width: 991px) {
  footer.style-4 .foot .links {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 30px 0;
  }
}

@media screen and (max-width: 991px) {
  footer.style-4 .foot .links li {
    width: 50%;
    margin: 5px 0;
  }
}

footer.style-4 .foot .links li a {
  color: black;
  margin-left: 0;
}

.team.style-6 {
  padding: 0 30px;
}

.team.style-61 {
  padding: 23px 30px;
}

.team.style-6 .content {
  background-color: #f1f2fa;
  position: relative;
  padding: 100px 0;
  border-radius: 30px;
}

.team.style-6 .content1 {
  background-color: #f1f2fa;
  padding: 20px 0;
  border-radius: 30px;
}

.bgmad {
  background-color: #f1f2fa;
  position: relative;
  padding: 20px 0;
}

.team-card.style-6 {
  text-align: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.card-title h5 h6 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.card-text {
  color: #999;
  font-size: 13px;
}

.pb-80 {
  padding-bottom: 80px !important;
}

@media screen and (min-width: 990px) {
  .carousel-item img {
    width: 96%;
    height: 500px;
    margin: 26px;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none !important;
}

.community.style-5 .content {
  border: 1px solid #9993;
  border-radius: 15px;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.community.style-5 .content .commun-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 33%;
  border-right: 1px solid #9993;
  padding: 10px 0;
}

.community.style-5 .content .commun-card .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 25px;
}

.icon-45 {
  width: 45px;
  height: 45px;
}

.community.style-5 .content .commun-card .icon img {
  -webkit-filter: drop-shadow(-10px 10px 20px #084cba77);
  filter: drop-shadow(-10px 10px 20px #084cba77);
  -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
  animation: scale_up_down 1s ease-in-out infinite alternate both;
}

.community.style-5 .content .commun-card .inf h5 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.1;
}

@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.contact.style-6 .content {
  position: relative;
  border-top: 10px solid #5842bc;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 100px 15px;
}

.contact.style-6 .content::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    45deg,
    transparent,
    #def1fa,
    transparent,
    #e8e8f3,
    transparent
  );
  background: -o-linear-gradient(
    45deg,
    transparent,
    #def1fa,
    transparent,
    #e8e8f3,
    transparent
  );
  background: linear-gradient(
    45deg,
    transparent,
    #def1fa,
    transparent,
    #e8e8f3,
    transparent
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 20s linear infinite;
  animation: gradient 20s linear infinite;
  border-radius: 30px;
  overflow: hidden;
}

.contact.style-6 .content .form {
  position: relative;
  z-index: 5;
}

.fs-12px {
  font-size: 12px !important;
}

.contact.style-6 .form-group .form-control,
.contact.style-6 .form-group .form-select {
  border: 1px solid #9994;
  border-radius: 30px;
  min-height: 50px;
  font-size: 12px;
  padding: 10px 25px;
}

.contact.style-6 .content .contact_a {
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 30px;
  pointer-events: none;
  max-width: 20%;
}

.contact.style-6 .content .contact_message {
  position: absolute;
  right: -150px;
  top: 100px;
  border-bottom-left-radius: 30px;
  pointer-events: none;
  max-width: 30%;
}

.contact-page.style-5 .map iframe {
  width: 100%;
  margin-bottom: -6px;
}

@media screen and (max-width: 991px) {
  .contact-page.style-5 .section-head {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 991px) {
  .community.style-4 .content,
  .community.style-5 .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 20px !important;
  }
}

@media screen and (max-width: 991px) {
  .community.style-4 .content .commun-card,
  .community.style-5 .content .commun-card {
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding: 30px !important;
  }
}

@media screen and (max-width: 991px) {
  .contact-page.style-5 .contact .content .contact_message {
    right: 0;
    top: 30px;
  }
}

.butn.blue5-3Dbutn,
.btn.blue5-3Dbutn {
  background: #157efb;
  -webkit-box-shadow: 0px 4px 0px 0px;
  box-shadow: 0px 4px 0px 0px #5842bc;
  -webkit-filter: drop-shadow(0 13px 20px #07397235);
  filter: drop-shadow(0 13px 20px #07397235);
  font-size: 12px;
  color: white;
}

.pt-50 {
  padding-top: 50px !important;
}

.portfolio-page.style-1 .portfolio-projects.style-1 .controls {
  text-align: center;
  margin-bottom: 50px;
}

.portfolio-page.style-1
  .portfolio-projects.style-1
  .controls
  button.mixitup-control-active {
  color: #157efb;
}

.portfolio-page.style-1 .portfolio-projects.style-1 .controls button {
  border: 0;
  background: transparent;
  font-weight: bold;
  text-transform: capitalize;
  margin: 10px;
}

.portfolio.style-1 {
  position: relative;
}

.portfolio.style-1 .portfolio-card {
  background-color: #fff;
  border-radius: 7px;
  overflow: hidden;
}

.portfolio.style-1 .portfolio-card .img {
  height: 240px;
  overflow: hidden;
}

.portfolio.style-1 .portfolio-card .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.portfolio.style-1 .portfolio-card .info {
  padding: 30px;
}

.portfolio.style-1 .portfolio-card .info h5 a {
  color: #000;
  font-weight: bold;
}

.portfolio.style-1 .portfolio-card .info small {
  font-size: 10px;
  letter-spacing: 1px;
  margin-top: 5px;
}

.color-main {
  color: #0066ff !important;
}

.portfolio.style-1 .portfolio-card .info .text {
  color: #666;
  margin: 15px 0 20px;
  font-size: 13px;
}

.portfolio.style-1 .portfolio-card .info .tags a {
  font-size: 11px;
  padding: 3px 7px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #eef4f8;
}

.portfolio-page.style-1 .download.style-5 {
  position: relative;
  overflow: hidden;
}

.download.style-5 .content h2 {
  font-size: 50px;
}

.download.style-5 .content h2 span {
  position: relative;
}

.portfolio-page.style-1 .download.style-5 .contact_globe {
  position: absolute;
  height: 150%;
  max-height: none;
  width: 70%;
  top: -25%;
  left: 20%;
  opacity: 0.15;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}
.portfolio .row .col-lg-4 {
  position: relative;
}

.portfolio .row .col-lg-4 .card {
  height: auto; /* Let the card height adjust to content */
  display: flex;
  flex-direction: column;
}

.portfolio .row .col-lg-4 .card .card-img-container {
  position: relative;
  height: 300px; /* Fixed height for the image container */
  background-color: transparent; /* Transparent background */
  /* border-radius: 1.5rem; */ /* Rounded corners (you can adjust this value) */
  overflow: hidden; /* Ensure child elements don't overflow the rounded corners */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Inner shadow */
}

.portfolio .row .col-lg-4 .card .card-img-top {
  height: 100%; /* Ensure the image takes full height of its container */
  object-fit: cover; /* Ensure the image covers the container without distortion */
  border: none; /* Remove border from image itself */
  padding-top: 10px; /* Adds 30px gap at the top of the image */
  padding-left: 10px;
  padding-right: 10px; /* Adds 30px gap at the top of the image */
  padding-bottom: 0px; /* Adds 30px gap to the left of the image */
  margin-top: 0; /* Ensure no other margin conflicts with the gap */
  margin-left: 0; /* Ensure no other margin conflicts with the gap */
}

.portfolio .row .col-lg-4 .card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio .row .col-lg-4 .card .overlay .text {
  color: white; /* Text color on overlay */
  font-size: 20px; /* Adjust text size */
  font-weight: bold;
  text-align: center;
}

.portfolio .row .col-lg-4 .card:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.portfolio .row .col-lg-4 .card:hover {
  cursor: pointer; /* Pointer cursor on hover */
}

.swiper-slide {
  width: 212.333px !important;
}

.services-slider.style-6 .swiper-slide.swiper-slide-active {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  padding: 0 20px;
  -webkit-filter: drop-shadow(5px 50px 30px #07397211);
  filter: drop-shadow(5px 50px 30px #07397211);
}

.services-slider.style-6 .swiper-slide {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  top: 0;
}

.service-card.style-6 {
  text-align: center;
  padding: 30px 8%;
  background-color: #f1f2fa;
  border-radius: 30px;
  overflow: hidden;
  margin: 20px 0;
  display: block;
  color: #000;
}

.service-card.style-6 .icon {
  -webkit-filter: drop-shadow(5px 15px 20px #07397255);
  filter: drop-shadow(5px 15px 20px #07397255);
  margin-bottom: 25px;
}
.service-card.style-6 .icon img {
  max-height: 100px;
  max-width: 100px;
  -o-object-fit: contain;
  object-fit: contain;
}

.service-card.style-6 .info h5 {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
}

.service-card.style-6 .info .text {
  font-size: 11px;
  color: #666;
}

.services-slider.style-6 .swiper-container {
  padding-bottom: 100px;
}

.team41 {
  display: flex;
  text-align: center;
  justify-content: center;
}

.service-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  transition: 0.3s;
}

.about.style-4 .content.frs-content .lines1,
.about.style-4 .content.trd-content .lines1 {
  position: absolute;
  left: 0;
  top: 0px;
  width: 40%;
  max-width: unset;
  max-height: unset;
}

.m-20 {
  margin: 20px;
}

@media screen and (min-width: 991px) {
  .imgmad {
    max-width: 50%;
  }
}

.ml-40 {
  margin-left: 40px;
}

@media screen and (max-width: 991px) {
  .col {
    flex: none !important;
  }
}

@media screen and (min-width: 991px) {
  .w-40 {
    width: 40%;
  }
}

.list-style-two > li > p {
  border-radius: 5px;
  border: 1px solid #707070;
  margin: 10px 0;
  padding: 0 10px;
}

@media screen and (min-width: 991px) {
  .madcaf {
    display: flex;
    gap: 20px;
  }

  .madwi {
    border-radius: 20px;
    width: 38%;
    height: 20%;
  }
}

.form-control {
  min-height: 50px;
  font-size: 12px;
  padding: 10px 50px 10px 20px;
}

.search-btn1 {
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.info1 h5 {
  font-size: 13px;
  color: black;
  font-weight: bold;
}

.info1 .text1 {
  font-size: 13px;
}

@media screen and (max-width: 991px) {
  .madwi1 {
    width: 30% !important;
    height: 60px !important;
  }
}

.madcaf1 {
  display: flex;
  gap: 20px;
}

.madwi1 {
  width: 50%;
  height: 79px;
}

.css-821wyw-MuiSvgIcon-root {
  margin-left: 6px;
}

.mt-60 {
  margin-top: 60px;
}

.pagination.color-4 a.active {
  color: #fff;
  border-color: #5842bc;
  background-color: #5842bc;
}

.pagination.style-5 a {
  width: 30px;
  height: 30px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: max-content;
  border: 1px solid #9993;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 11px;
  color: #666;
}

.pagination.style-5 a .text {
  padding: 3px 10px;
  font-size: 11px;
}

.style-5 .side-blog .title {
  font-size: 16px;
  font-weight: 600 !important;
  letter-spacing: 2px;
}

.side-categories .cat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #666;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 0;
  border-bottom: 1px solid #9995;
}

.rounded-circle1 {
  border-radius: 20px;
  background-color: #eaeef2;
}

.madbu {
  width: 282px;
  background-color: #5842bc !important;
  border-radius: 20px !important;
}

@media screen and (max-width: 1200px) {
  .madbu {
    width: 200px;
    background-color: #5842bc !important;
    border-radius: 20px !important;
  }
}

.side-share .social-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f7fe;
  color: #666;
  font-size: 12px;
  margin: 0 3px;
}

.side-share .social-icon:hover {
  background-color: #5842bc;
  color: white;
}

/* About section List manage */
.custom-list {
  list-style-type: disc;
  padding-left: 20px;
  /* Adjust the padding to position the disc correctly */
}

.custom-list li {
  display: list-item;
  margin-bottom: 1rem;
  /* Adjust the margin as needed */
}

.borra {
  border-radius: 20px;
}

.side-tags a {
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eef4f8;
  color: black;
  margin-bottom: 4px;
}

@media screen and (max-width: 991px) {
  .borra {
    border-radius: 20px;
    width: 200px !important;
  }
}

.css-i4bv87-MuiSvgIcon-root {
  margin-right: 7px;
}

.scalmad:hover {
  scale: 0.9;
  transition: 0.4s;
}

@media screen and (min-width: 991px) {
  .mad44 {
    display: flex;
  }
}

@media screen and (min-width: 991px) {
  .mad88 {
    margin-left: 46px;
  }
}

.btwi {
  width: 62%;
}

.fixedhead {
  position: fixed;
  width: 100%;
  z-index: 200;
}

.pt6 {
  padding-top: 8% !important;
}

@media screen and (max-width: 991px) {
  .pt6 {
    padding-top: 25% !important;
  }
}
